<script lang="ts" setup>
import { computed } from 'vue'
import type AuditTeamMember from '~/src/Domain/Engagement/AuditTeamMember'
import type User from '~/src/Domain/Identity/User'
import type Member from '~/src/Domain/Organisation/Member'

const props = defineProps<{
  user: User | AuditTeamMember | Member
  title?: string
  small?: boolean
  large?: boolean
  online?: boolean
  card?: boolean
}>()

const initials = computed(() => props.user.getInitials())
</script>

<template>
  <div
    class="avatar"
    :class="{
      'avatar-sm': small,
      'avatar-lg': large,
      'avatar-online': online,
      'card-avatar': card,
    }"
  >
    <ui-img
      v-if="user.avatar"
      :alt="title ?? initials"
      :src="user.avatar"
      :title="title"
      class="avatar-img rounded-circle"
    />
    <span
      v-else
      class="avatar-title rounded-circle"
    >
      {{ initials }}
    </span>
  </div>
</template>

<style lang="scss">
.avatar-online .avatar-img {
  mask-image: inherit;
}
</style>
